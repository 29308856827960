import {
  CreateMaxFileGqlQuery,
  CreateMaxFileGqlStatus,
  FetchMaxFilesCacheKey,
  MaxFileGqlError,
  MaxFileID,
  MaxFileName,
  MaxFileSize,
  MaxFileNanoID,
  MaxFilePresignedUrl,
  MaxFileUUID
} from '../../maxFilesTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateMaxFileOptions {
  query: CreateMaxFileGqlQuery;
  cacheKeys?: FetchMaxFilesCacheKey[];
}

interface CreateMaxFileRecordResponse {
  id: MaxFileID;
  uuid: MaxFileUUID;
  presignedUrl: MaxFilePresignedUrl;
}

export interface CreateMaxFileResponse {
  createMaxFile: {
    status: CreateMaxFileGqlStatus;
    recordUuid: MaxFileUUID;
    recordNanoId: MaxFileNanoID;
    record: CreateMaxFileRecordResponse;
    errors: CreateMaxFileError;
  };
}

export interface CreateMaxFileInput {
  name: MaxFileName;
  size: MaxFileSize;
}

export interface CreateMaxFileError {
  fullMessages: MaxFileGqlError;
}

const action = 'createMaxFile';

function useCreateMaxFile({ query, cacheKeys }: CreateMaxFileOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateMaxFileInput,
    CreateMaxFileResponse,
    CreateMaxFileError,
    CreateMaxFileRecordResponse
  >({ action, cacheKeys, query });

  return {
    createMaxFileData: createQueryData,
    createMaxFileError: createQueryError,
    createMaxFileLoading: createQueryLoading,
    createMaxFileErrorMessage: createQueryErrorMessage,
    createMaxFile: createQuery,
    createMaxFileReset: createQueryReset
  };
}

export default useCreateMaxFile;
