import { UpdateItemCacheKeys } from '../../../../types';

import { ImageUUID, UpdateImageGqlQuery } from '../../imagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import { ImageItemImageVersions } from '../../../../helpers/ImageHelper';

interface UpdateImageInfoOptions {
  query: UpdateImageGqlQuery;
  cacheKeys?: UpdateItemCacheKeys;
}

export interface UpdateImageInfoInput {
  clientMutationId?: string;
  uuid: ImageUUID;
  async: boolean;
  versions?: ImageItemImageVersions[];
}

export interface UpdateImageInfoError {
  fullMessages: string[] | null;
}

export interface UpdateImageInfoResponse {
  updateImageInfo: {
    status: string;
    errors: UpdateImageInfoError;
  };
}

export const updateImageInfoAction = 'updateImageInfo';

function useUpdateImageInfo({ query, cacheKeys }: UpdateImageInfoOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    UpdateImageInfoInput,
    UpdateImageInfoResponse,
    UpdateImageInfoError,
    undefined
  >({
    action: updateImageInfoAction,
    cacheKeys,
    query
  });

  return {
    updateImageInfoData: updateQueryData,
    updateImageInfoError: updateQueryError,
    updateImageInfoLoading: updateQueryLoading,
    updateImageInfoErrorMessage: updateQueryErrorMessage,
    updateImageInfo: updateQuery,
    updateImageInfoReset: updateQueryReset
  };
}

export default useUpdateImageInfo;
