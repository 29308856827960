import React, { Fragment, useCallback } from 'react';
import keys from 'lodash/keys';

import {
  DropzoneUploadedFile,
  DropzoneUploadedFileItem
} from '../DropzoneUploadedFile';

interface DropzoneUploadedFilesProps {
  activeTab: string;
  allFiles: { [id: string]: DropzoneUploadedFileItem & { activeTab: string } };
  onRemoveFile: (id: string) => void;
}

function DropzoneUploadedFiles({
  activeTab,
  allFiles,
  onRemoveFile
}: DropzoneUploadedFilesProps) {
  const handleRemove = useCallback<(id: string) => void>(
    (id: string) => {
      onRemoveFile(id);
    },
    [onRemoveFile]
  );

  return (
    <div className="mt-3 -mr-3 sm:mr-0">
      <ul className="space-y-2 w-full overflow-hidden">
        {keys(allFiles).map((id) => (
          <Fragment key={id}>
            {allFiles[id].activeTab === activeTab ? (
              <DropzoneUploadedFile
                id={id}
                {...allFiles[id]}
                onRemove={handleRemove}
              />
            ) : null}
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

export default DropzoneUploadedFiles;
