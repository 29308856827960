import slugify from 'slugify';

import { FileName } from '../../types';

const exponents = {
  '⁰': '0',
  '¹': '1',
  '²': '2',
  '³': '3',
  '⁴': '4',
  '⁵': '5',
  '⁶': '6',
  '⁷': '7',
  '⁸': '8',
  '⁹': '9',
  '₀': '0',
  '₁': '1',
  '₂': '2',
  '₃': '3',
  '₄': '4',
  '₅': '5',
  '₆': '6',
  '₇': '7',
  '₈': '8',
  '₉': '9'
};

const specCharsRegExp = /[\s/\\:*?[\]()^=!,`’'"#;@+№~]/g;

function slugifyFileName(fileName: FileName) {
  slugify.extend(exponents);

  return slugify(fileName.replace(specCharsRegExp, '-'));
}
export default slugifyFileName;
