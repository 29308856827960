import { gql } from 'graphql-request';

export const CREATE_IMAGE_QUERY = gql`
  mutation CreateImage($name: String!, $size: BigInteger) {
    createImage(input: { name: $name, size: $size }) {
      status
      recordUuid
      recordNanoId
      record {
        id
        uuid
        nanoId
        name
        size
        presignedUrl
        presignedUrls
      }
      errors {
        fullMessages
      }
    }
  }
`;
