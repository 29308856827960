import { gql } from 'graphql-request';

export const UPDATE_MAX_FILE_INFO_QUERY = gql`
  mutation UpdateMaxFileInfo($uuid: ID!, $async: Boolean) {
    updateMaxFileInfo(input: { uuid: $uuid, async: $async }) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
