import { gql } from 'graphql-request';

export const UPDATE_IMAGE_INFO_QUERY = gql`
  mutation UpdateImageInfo($uuid: ID!, $async: Boolean, $versions: [String!]) {
    updateImageInfo(
      input: { uuid: $uuid, async: $async, versions: $versions }
    ) {
      status
      errors {
        fullMessages
      }
    }
  }
`;
