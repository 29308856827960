import {
  CreateFileAttachmentGqlQuery,
  CreateFileAttachmentGqlStatus,
  FetchFileAttachmentsCacheKey,
  FileAttachmentDrawing,
  FileAttachmentGqlError,
  FileAttachmentID,
  FileAttachmentName,
  FileAttachmentNanoID,
  FileAttachmentPresignedUrl,
  FileAttachmentReference,
  FileAttachmentSize,
  FileAttachmentTexture,
  FileAttachmentUUID
} from '../../fileAttachmentsTypes';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

interface CreateFileAttachmentOptions {
  query: CreateFileAttachmentGqlQuery;
  cacheKeys?: FetchFileAttachmentsCacheKey[];
}

interface CreateFileAttachmentRecordResponse {
  id: FileAttachmentID;
  uuid: FileAttachmentUUID;
  presignedUrl: FileAttachmentPresignedUrl;
  presignedUrls: FileAttachmentPresignedUrl;
}

export interface CreateFileAttachmentResponse {
  createFileAttachment: {
    status: CreateFileAttachmentGqlStatus;
    recordUuid: FileAttachmentUUID;
    recordNanoId: FileAttachmentNanoID;
    record: CreateFileAttachmentRecordResponse;
    errors: CreateFileAttachmentError;
  };
}

export interface CreateFileAttachmentInput {
  name: FileAttachmentName;
  size: FileAttachmentSize;
  drawing?: FileAttachmentDrawing;
  reference?: FileAttachmentReference;
  texture?: FileAttachmentTexture;
}

export interface CreateFileAttachmentError {
  fullMessages: FileAttachmentGqlError;
}

const action = 'createFileAttachment';

function useCreateFileAttachment({
  query,
  cacheKeys
}: CreateFileAttachmentOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateFileAttachmentInput,
    CreateFileAttachmentResponse,
    CreateFileAttachmentError,
    CreateFileAttachmentRecordResponse
  >({ action, cacheKeys, query });

  return {
    createFileAttachmentData: createQueryData,
    createFileAttachmentError: createQueryError,
    createFileAttachmentLoading: createQueryLoading,
    createFileAttachmentErrorMessage: createQueryErrorMessage,
    createFileAttachment: createQuery,
    createFileAttachmentReset: createQueryReset
  };
}

export default useCreateFileAttachment;
