import { gql } from 'graphql-request';

export const CREATE_FILE_ATTACHMENT_QUERY = gql`
  mutation CreateFileAttachment(
    $name: String!
    $size: BigInteger
    $drawing: Boolean
    $reference: Boolean
    $texture: Boolean
  ) {
    createFileAttachment(
      input: {
        name: $name
        size: $size
        drawing: $drawing
        reference: $reference
        texture: $texture
      }
    ) {
      status
      recordUuid
      recordNanoId
      record {
        id
        uuid
        nanoId
        name
        size
        drawing
        reference
        texture
        presignedUrl
        presignedUrls
      }
      errors {
        fullMessages
      }
    }
  }
`;
