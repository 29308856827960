import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { IconsEnum } from '../../../assets/icons/types';

import { useS3MultipartUpload } from '../../../main/s3Multipart/hooks/useS3MultipartUpload';

import { Icon } from '../../Icon';
import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';
import { Translate } from '../../Translate';

import { stringsKeys, words } from '../../../locales/keys';

import {
  S3MultipartDropzoneAreaProps,
  S3MultipartDropzoneAreaRequiredProps
} from './S3MultipartDropzoneArea.types';

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

axiosRetry(axios, { retries: 20, retryDelay: axiosRetry.exponentialDelay });

function S3MultipartDropzoneArea({
  type,
  dataParams,
  disabled,
  maxFiles,
  activeFilesCount,
  preventMaxFilesOverload,
  onFilesAccepted,
  onFileCreated,
  onUploadProgress,
  onFileUploaded,
  onFileFailed,
  onFinishUpload
}: S3MultipartDropzoneAreaProps & S3MultipartDropzoneAreaRequiredProps) {
  const { onUpload } = useS3MultipartUpload({
    type,
    dataParams,
    maxFiles,
    activeFilesCount,
    preventMaxFilesOverload,
    onFilesAccepted,
    onFileCreated,
    onUploadProgress,
    onFileUploaded,
    onFileFailed,
    onFinishUpload
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: onUpload,
    disabled,
    maxFiles
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div
      className="sm:border-2 sm:border-dashed border-gray-300 dark:border-gray-700 rounded-md sm:p-1 relative text-sm"
      {...getRootProps({ style })}
    >
      <input
        className="absolute w-0 h-0 block invisible opacity-0 -z-1"
        {...getInputProps()}
      />
      <div className="flex items-center">
        <div className="mr-auto p-2 text-gray-500 hidden sm:inline">
          <Icon
            className="h-6 w-6 inline-block mr-2"
            icon={IconsEnum.CLOUD_UPLOAD_OUTLINE}
          />
          {isDragActive ? (
            <span>
              <Translate id={stringsKeys.dropTheFilesHere} />
            </span>
          ) : (
            <span>
              <Translate id={stringsKeys.dragAndDropFilesOr} />
            </span>
          )}
        </div>
        <div className="sm:text-gray-500">
          <PureIconButtonHelper
            className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.FOLDER_OPEN_OUTLINE}
            i18nText={words.browse}
          />
        </div>
      </div>
    </div>
  );
}

export default S3MultipartDropzoneArea;
